import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BlockingLoaderService } from '@WebUi/app/services/blocking-loader.service';
import { NonBlockingLoaderService } from '@WebUi/app/services/non-blocking-loader.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { APP_TITLE } from '@WebUi/helpers/injection-tokens';
import { Title } from '@angular/platform-browser';
import { FADE_ANIMATION_OPTIONS } from '@WebUi/helpers/animation';
import { LoggingOutBlockingLoaderService } from '@WebUi/app/services/logging-out-blocking-loader.service';

@Component({
  selector: 'aeb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInOnEnterAnimation(FADE_ANIMATION_OPTIONS),
    fadeOutOnLeaveAnimation(FADE_ANIMATION_OPTIONS),
  ],
})
export class AppComponent {

  constructor(
    @Inject(APP_TITLE) public appTitle: string,
    private titleService: Title,
    public blockingLoaderService: BlockingLoaderService,
    public nonBlockingLoaderService: NonBlockingLoaderService,
    public loggingOutBlockingLoaderService: LoggingOutBlockingLoaderService,
  ) {
    this.titleService.setTitle(this.appTitle);
  }

}
