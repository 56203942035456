import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AppStateModel, APP_STATE_DEFAULTS } from '@WebUi/app/models/app-state.model';
import { SetLang, StoreAppVersion } from '@WebUi/app/store/app.actions';
import { Lang } from '@Libs/model';
import { InterpolatableTranslationObject, TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@State<AppStateModel>({
  name: 'app',
  defaults: APP_STATE_DEFAULTS,
})
@Injectable()
export class AppState {

  @Selector([AppState])
  static lang(state: AppStateModel): Lang {
    return state.lang;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
  ) { }

  @Action(SetLang)
  setLang(ctx: StateContext<AppStateModel>, payload: SetLang): Observable<InterpolatableTranslationObject> {
    return this.translateService.use(payload.lang)
      .pipe(
        tap(() => {
          this.document.documentElement.lang = payload.lang;

          ctx.patchState({
            lang: payload.lang,
          });
        }),
      );
  }

  @Action(StoreAppVersion)
  storeAppVersion(ctx: StateContext<AppStateModel>, payload: StoreAppVersion): void {
    ctx.patchState({
      appVersion: payload.appVersion,
    });
  }

}
