<router-outlet></router-outlet>

<ng-container *ngIf="nonBlockingLoaderService.loading$ | async">
  <div class="progress" [@fadeInOnEnter] [@fadeOutOnLeave]>
    <div class="indeterminate"></div>
  </div>
</ng-container>

<ng-container *ngIf="blockingLoaderService.loading$ | async">
  <aeb-backdrop class="blocking-loader-backdrop" [@fadeInOnEnter] [@fadeOutOnLeave]>
    <aeb-spinner></aeb-spinner>
  </aeb-backdrop>
</ng-container>

<ng-container *ngIf="loggingOutBlockingLoaderService.loading$ | async">
  <aeb-backdrop class="logging-out-blocking-loader-backdrop" [@fadeInOnEnter] [@fadeOutOnLeave]>
    <div class="logging-out-blocking-loader-content">
      <picture>
        <img class="img-fluid" src="/assets/logo.svg" width="354" height="50" [attr.alt]="appTitle">
      </picture>

      <aeb-spinner class="logging-out-blocking-loader-spinner"></aeb-spinner>

      <div class="logging-out-blocking-loader-text text-muted" translate>Components.App.LoggingOut</div>
    </div>
  </aeb-backdrop>
</ng-container>
