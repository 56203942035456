import {
  DateRangeOption,
  PayoutSummaryBookkeepingReport,
  PayoutSummaryBookkeepingReportListRequest,
  PayoutSummaryBookkeepingReportListResponse,
  PayoutSummaryBookkeepingReportSortKeyType,
  PayoutSummaryBookkeepingReportStatus,
  PayoutTransactionReportListRequest,
  PayoutTransactionReportSortKeyType,
  SortDirectionType,
} from '@Libs/model';

export const DEFAULT_PAYOUT_TRANSACTION_REPORTS_SORT_DIRECTION_TYPE = SortDirectionType.DESC;

export const DEFAULT_PAYOUT_TRANSACTION_REPORTS_SORT_KEY_TYPE = PayoutTransactionReportSortKeyType.TRANSACTION_DATE_UTC;

export const DEFAULT_PAYOUT_SUMMARY_BOOKKEEPING_REPORTS_SORT_DIRECTION_TYPE = SortDirectionType.DESC;

export const DEFAULT_PAYOUT_SUMMARY_BOOKKEEPING_REPORTS_SORT_KEY_TYPE = PayoutSummaryBookkeepingReportSortKeyType.PAYOUT_DATE_UTC;

export const INITIAL_PAYOUT_REPORTS_AMOUNT = 50;

export const LOAD_MORE_PAYOUT_REPORTS_AMOUNT = 50;

export enum ExtraPayoutSummaryBookkeepingReportStatus {
  DRAFT_BOOKKEEPT = 4,
  DRAFT_BOOKKEEPT_WITH_WARNING = 5,
}

export type ExtendedPayoutSummaryBookkeepingReportStatus = PayoutSummaryBookkeepingReportStatus | ExtraPayoutSummaryBookkeepingReportStatus;

export type ExtendedPayoutSummaryBookkeepingReport = PayoutSummaryBookkeepingReport & {
  status: ExtendedPayoutSummaryBookkeepingReportStatus;
};

export type ExtendedPayoutSummaryBookkeepingReportListResponse = PayoutSummaryBookkeepingReportListResponse & {
  reports: ExtendedPayoutSummaryBookkeepingReport[];
};

export type PayoutTransactionReportListOptions = PayoutTransactionReportListRequest & {
  dateRangeOption: DateRangeOption | null;
};

export type PayoutSummaryBookkeepingReportListOptions = PayoutSummaryBookkeepingReportListRequest & {
  dateRangeOption: DateRangeOption | null;
};
