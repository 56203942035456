import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';
import { concat, filter, interval, take } from 'rxjs';
import { RollbarService } from '@WebUi/app/services/rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class AppUpdatesCheckerService {

  static readonly SIX_HOURS: number = 6 * 60 * 60 * 1000;

  private _lastVisibleTimestamp: number = Number.NEGATIVE_INFINITY;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private rollbarService: RollbarService,
  ) { }

  init(): void {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    concat(
      this.applicationRef.isStable
        .pipe(
          filter((isStable: boolean) => isStable),
          take(1),
        ),
      interval(AppUpdatesCheckerService.SIX_HOURS),
    )
      .subscribe(() => {
        this.swUpdate.checkForUpdate();
      });

    this.swUpdate.versionUpdates
      .subscribe((event: VersionEvent) => {
        switch (event.type) {
          case 'VERSION_READY':
            this.document.location.reload();

            break;
          case 'VERSION_INSTALLATION_FAILED':
            this.rollbarService.error('SwUpdate:VERSION_INSTALLATION_FAILED', event);

            break;
        }
      });

    this.swUpdate.unrecoverable
      .subscribe((event: UnrecoverableStateEvent) => {
        this.rollbarService.error('SwUpdate:UNRECOVERABLE_STATE', event);

        this.document.location.reload();
      });

    this.document.addEventListener('visibilitychange', () => {
      const now: number = Date.now();

      switch (this.document.visibilityState) {
        case 'hidden':
          this._lastVisibleTimestamp = now;

          break;
        case 'visible':
          if (now - this._lastVisibleTimestamp > AppUpdatesCheckerService.SIX_HOURS) {
            this.swUpdate.checkForUpdate();
          }

          break;
      }
    });
  }

}
