import { DOCUMENT, Location } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { ProtectedResourceScopes } from '@azure/msal-angular/msal.interceptor.config';
import { AccountInfo, AuthenticationResult, StringUtils } from '@azure/msal-browser';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '@WebUi/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastsService } from '@Libs/toasts';
import { ToastSimpleContentComponent } from '@WebUi/shared/components/toast-templates/simple-content/simple-content.component';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationInterceptor implements HttpInterceptor {

  private readonly stopAuthorizedRequests$$: Subject<void> = new Subject<void>();
  readonly stopAuthorizedRequests$: Observable<void> = this.stopAuthorizedRequests$$.asObservable();

  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private msalService: MsalService,
    private location: Location,
    private translateService: TranslateService,
    private toastsService: ToastsService,
  ) { }

  // eslint-disable-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const scopes: string[] | null = this.getScopesForEndpoint(request.url, request.method);

    // If no scopes for endpoint, does not acquire token
    if (!scopes || scopes.length === 0) {
      return next.handle(request)
        .pipe(
          catchError((response: HttpErrorResponse) => this.handleUnauthorizedResponseError(response)),
        );
    }

    const account: AccountInfo | null = this.msalService.instance.getActiveAccount();

    if (!account) {
      return next.handle(request)
        .pipe(
          catchError((response: HttpErrorResponse) => this.handleUnauthorizedResponseError(response)),
        );
    }

    return this.authService.acquireTokenSilent({
      scopes,
    })
      .pipe(
        switchMap((authenticationResult: AuthenticationResult) => {
          const modifiedRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${authenticationResult.accessToken}`),
          });

          return next.handle(modifiedRequest)
            .pipe(
              takeUntil(this.stopAuthorizedRequests$),
              catchError((response: HttpErrorResponse) => this.handleUnauthorizedResponseError(response)),
            );
        }),
      );
  }

  private handleUnauthorizedResponseError(response: HttpErrorResponse): Observable<never> {
    if (response.status === 401) {
      this.translateService.get('Toasts.HttpErrorResponse.401')
        .subscribe((translation: any) => {
          this.toastsService.error({
            heading: translation['Heading'],
            contentFactory: {
              component: ToastSimpleContentComponent,
              properties: {
                content: translation['Description'],
              },
            },
            autoclose: false,
          });
        });

      this.stopAuthorizedRequests$$.next();

      this.authService.logout()
        .subscribe();

      // Stop propogating error response, just wait for logout redirect
      return EMPTY;
    }

    return throwError(() => response);
  }

  /**
   * Looks up the scopes for the given endpoint from the protectedResourceMap
   * @param endpoint Url of the request
   * @param httpMethod Http method of the request
   * @returns Array of scopes, or null if not found
   *
   */
  private getScopesForEndpoint(
    endpoint: string,
    httpMethod: string
  ): Array<string> | null {
    this.msalService
      .getLogger()
      .verbose('Interceptor - getting scopes for endpoint');

    // Ensures endpoints and protected resources compared are normalized
    const normalizedEndpoint = this.location.normalize(endpoint);

    const protectedResourcesArray = Array.from(
      this.msalInterceptorConfig.protectedResourceMap.keys()
    );

    const matchingProtectedResources = this.matchResourcesToEndpoint(
      protectedResourcesArray,
      normalizedEndpoint
    );

    if (matchingProtectedResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources,
        httpMethod
      );
    }

    return null;
  }

  /**
   * Finds resource endpoints that match request endpoint
   * @param protectedResourcesEndpoints
   * @param endpoint
   * @returns
   */
  private matchResourcesToEndpoint(
    protectedResourcesEndpoints: string[],
    endpoint: string
  ): Array<string> {
    const matchingResources: Array<string> = [];

    protectedResourcesEndpoints.forEach((key) => {
      const normalizedKey = this.location.normalize(key);

      // Get url components
      const absoluteKey = this.getAbsoluteUrl(normalizedKey);
      const keyComponents = new URL(absoluteKey);
      const absoluteEndpoint = this.getAbsoluteUrl(endpoint);
      const endpointComponents = new URL(absoluteEndpoint);

      if (this.checkUrlComponents(keyComponents, endpointComponents)) {
        matchingResources.push(key);
      }
    });

    return matchingResources;
  }

  /**
   * Compares URL segments between key and endpoint
   * @param key
   * @param endpoint
   * @returns
   */
  private checkUrlComponents(
    keyComponents: URL,
    endpointComponents: URL
  ): boolean {
    // URL properties from https://developer.mozilla.org/en-US/docs/Web/API/URL
    const urlProperties = ['protocol', 'host', 'pathname', 'search', 'hash'] satisfies (keyof URL)[];

    for (const property of urlProperties) {
      if (keyComponents[property]) {
        const decodedInput = decodeURIComponent(keyComponents[property]);
        if (
          !StringUtils.matchPattern(decodedInput, endpointComponents[property])
        ) {
          return false;
        }
      }
    }

    return true;
  }

  /**
   * Transforms relative urls to absolute urls
   * @param url
   * @returns
   */
  private getAbsoluteUrl(url: string): string {
    const link: HTMLAnchorElement = this.document.createElement('a');

    link.href = url;

    return link.href;
  }

  /**
   * Finds scopes from first matching endpoint with HTTP method that matches request
   * @param protectedResourceMap Protected resource map
   * @param endpointArray Array of resources that match request endpoint
   * @param httpMethod Http method of the request
   * @returns
   */
  private matchScopesToEndpoint(
    protectedResourceMap: Map<
      string,
      Array<string | ProtectedResourceScopes> | null
    >,
    endpointArray: string[],
    httpMethod: string
  ): Array<string> | null {
    const allMatchedScopes: (string[] | null)[] = [];

    // Check each matched endpoint for matching HttpMethod and scopes
    endpointArray.forEach((matchedEndpoint: string) => {
      const scopesForEndpoint: string[] = [];
      const methodAndScopesArray = protectedResourceMap.get(matchedEndpoint);

      // Return if resource is unprotected
      if (!methodAndScopesArray) {
        allMatchedScopes.push(null);

        return;
      }

      methodAndScopesArray.forEach((entry) => {
        // Entry is either array of scopes or ProtectedResourceScopes object
        if (typeof entry === 'string') {
          scopesForEndpoint.push(entry);
        } else {
          // Ensure methods being compared are normalized
          const normalizedRequestMethod = httpMethod.toLowerCase();
          const normalizedResourceMethod = entry.httpMethod.toLowerCase();
          // Method in protectedResourceMap matches request http method
          if (normalizedResourceMethod === normalizedRequestMethod) {
            // Validate if scopes comes null to unprotect the resource in a certain http method
            if (entry.scopes === null) {
              allMatchedScopes.push(null);
            } else {
              entry.scopes.forEach((scope) => {
                scopesForEndpoint.push(scope);
              });
            }
          }
        }
      });

      // Only add to all scopes if scopes for endpoint and method is found
      if (scopesForEndpoint.length > 0) {
        allMatchedScopes.push(scopesForEndpoint);
      }
    });

    if (allMatchedScopes.length > 0) {
      if (allMatchedScopes.length > 1) {
        this.msalService
          .getLogger()
          .warning(
            'Interceptor - More than 1 matching scopes for endpoint found.'
          );
      }

      // Returns scopes for first matching endpoint
      return allMatchedScopes[0];
    }

    return null;
  }

}
