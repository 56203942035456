import { formatDate } from '@angular/common';
import { environment } from '@WebUi/env';

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
export const DEFAULT_DATE_TIME_FORMAT = environment.production ? 'dd.MM.yyyy, HH:mm' : 'dd.MM.yyyy, HH:mm:ss';

export function convertTimestatampFromMillisecondsToSeconds(timeStampMilliseconds: number): number {
  return Math.floor(timeStampMilliseconds / 1000);
}

export function convertTimestatampFromSecondsToMilliseconds(timeStampSeconds: number): number {
  return timeStampSeconds * 1000;
}

export function convertDateToTimestampInSeconds(date: Date): number {
  return convertTimestatampFromMillisecondsToSeconds(date.valueOf());
}

export function formatTimestampSecondsAsYYYYMMDD(timestampSeconds: number): string {
  return formatDate(convertTimestatampFromSecondsToMilliseconds(timestampSeconds), 'yyyy-MM-dd', 'en', '+0000');
}
