import { AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { Dialog, DIALOG_SCROLL_STRATEGY, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { AnimatedDialogRef } from './animated-dialog-ref';
import { BasePortalOutlet, ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable, Injector, Optional, SkipSelf, TemplateRef } from '@angular/core';
import { AnimatedDialogConfig } from './animated-dialog-config';
import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { DEFAULT_ANIMATED_DIALOG_CONFIG } from './animated-dialog-injectors';

declare const ngDevMode: unknown;

/** Unique id for the created dialog. */
let uniqueId = 0;

@Injectable({
  providedIn: 'root',
})
export class AnimatedDialog extends Dialog {

  constructor(
    private __overlay: Overlay,
    private __injector: Injector,
    @Optional() @Inject(DEFAULT_ANIMATED_DIALOG_CONFIG) private __defaultAnimatedOptions: AnimatedDialogConfig,
    @Optional() @SkipSelf() private __parentDialog: Dialog,
    private __overlayContainer: OverlayContainer,
    @Inject(DIALOG_SCROLL_STRATEGY) _scrollStrategy: any,
    private _animationBuilder: AnimationBuilder,
  ) {
    super(__overlay, __injector, __defaultAnimatedOptions, __parentDialog, __overlayContainer, _scrollStrategy);
  }

  override open<R = unknown, D = unknown, C = unknown>(
    componentOrTemplateRef: ComponentType<C> | TemplateRef<C>,
    config?: AnimatedDialogConfig<D, DialogRef<R, C>>,
  ): DialogRef<R, C> {
    const defaults = (this.__defaultAnimatedOptions || new DialogConfig()) as DialogConfig<
      D,
      DialogRef<R, C>
    >;
    config = { ...defaults, ...config };
    config.id = config.id || `cdk-animated-dialog-${uniqueId++}`;

    if (
      config.id &&
      this.getDialogById(config.id) &&
      (typeof (ngDevMode) === 'undefined' || (ngDevMode))
    ) {
      throw new Error(`Dialog with id "${config.id}" exists already. The dialog id must be unique.`);
    }

    const overlayConfig = (this as any)._getOverlayConfig(config);
    const overlayRef = this.__overlay.create(overlayConfig);
    const dialogRef = new AnimatedDialogRef(overlayRef, config, this._animationBuilder);
    const dialogContainer = (this as any)._attachContainer(overlayRef, dialogRef, config);

    (dialogRef as { containerInstance: BasePortalOutlet }).containerInstance = dialogContainer;
    (this as any)._attachDialogContent(componentOrTemplateRef, dialogRef, dialogContainer, config);

    // If this is the first dialog that we're opening, hide all the non-overlay content.
    if (!this.openDialogs.length) {
      (this as any)._hideNonDialogContentFromAssistiveTechnology();
    }

    (this.openDialogs as DialogRef<R, C>[]).push(dialogRef);
    dialogRef.closed.subscribe(() => (this as any)._removeOpenDialog(dialogRef, true));

    if (config.enterAnimation && !(overlayRef as any)._animationsDisabled) {
      const enterAnimationPlayer: AnimationPlayer = this._animationBuilder.build(config.enterAnimation.animation).create(dialogContainer._elementRef.nativeElement, config.enterAnimation.options);

      enterAnimationPlayer.onDone(() => {
        this.afterOpened.next(dialogRef);

        enterAnimationPlayer.destroy();
      });

      enterAnimationPlayer.play();
    } else {
      this.afterOpened.next(dialogRef);
    }

    return dialogRef;
  }

}
