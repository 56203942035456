import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { APP_TITLE, POWERED_BY } from '@WebUi/helpers/injection-tokens';

@Component({
  selector: 'aeb-app-layout-login',
  templateUrl: './layout-login.component.html',
  styleUrls: ['./layout-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutLoginComponent {

  constructor(
    @Inject(APP_TITLE) public appTitle: string,
    @Inject(POWERED_BY) public poweredBy: string,
  ) { }

}
