import { Injectable } from '@angular/core';
import {
  CompleteCompanyConnection,
  CreateCompanyConnection,
  DeleteCompany,
  DeleteCompanyConnection,
  RevokeCompanyAccess,
  UpdateCompany,
  UpdateCompanyConnection,
} from '@WebUi/dashboard/store/dashboard.actions';
import {
  ActionCompletion,
  Actions,
  ActionType,
  ofActionCompleted,
  ofActionDispatched,
  ofActionErrored,
  ofActionSuccessful,
} from '@ngxs/store';
import { NonBlockingLoaderService } from '@WebUi/app/services/non-blocking-loader.service';
import { BlockingLoaderService } from '@WebUi/app/services/blocking-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { RouteConfigLoadStart, Router, Event, RouteConfigLoadEnd } from '@angular/router';
import { LoadMoreInvoiceReports } from '@WebUi/invoices/store/invoices.actions';
import { LoadMoreOperationReports, LoadMoreRecentReports } from '@WebUi/product-sync/store/product-sync.actions';
import { LoadMorePayoutTransactionReports } from '@WebUi/settlements/store/settlements.actions';
import { ToastsService } from '@Libs/toasts';

@Injectable({
  providedIn: 'root',
})
export class ActionsWatcherService {

  // !!! IMPORTANT
  // !!! DO NOT HANDLE <CreateCompany | GetUser> ACTIONS HERE
  // !!! IT USES IN MANY PLACES AND SHOULD BE HANDLED MANUALY

  readonly ACTIONS_FOR_BLOCKING_LOADER: ActionType[] = [
    DeleteCompany,
    CompleteCompanyConnection,
    CreateCompanyConnection,
  ];

  readonly ACTIONS_FOR_NON_BLOCKING_LOADER: ActionType[] = [
    RevokeCompanyAccess,
    UpdateCompany,
    UpdateCompanyConnection,
    DeleteCompanyConnection,
    LoadMoreInvoiceReports,
    LoadMoreRecentReports,
    LoadMoreOperationReports,
    LoadMorePayoutTransactionReports,
  ];

  readonly SUCCESSFUL_ACTIONS_FOR_TOASTS: ActionType[] = [
    DeleteCompany,
    RevokeCompanyAccess,
    UpdateCompany,
    UpdateCompanyConnection,
  ];

  readonly ERRORED_ACTIONS_FOR_TOASTS: ActionType[] = [
    CreateCompanyConnection,
    DeleteCompany,
    RevokeCompanyAccess,
    UpdateCompany,
    UpdateCompanyConnection,
  ];

  constructor(
    private actions$: Actions,
    private blockingLoaderService: BlockingLoaderService,
    private nonBlockingLoaderService: NonBlockingLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private toastsService: ToastsService,
  ) { }

  init(): void {
    this.initBlockingLoader();
    this.initNonBlockingLoader();
    this.initToasts();
  }

  private initToasts(): void {
    this.actions$
      .pipe(
        ofActionSuccessful(...this.SUCCESSFUL_ACTIONS_FOR_TOASTS),
        // DO NOT USE action.constructor.name here: prod build obfuscates class names so when class name is obfuscated we are not able to get correct translation for it
        map((action: ActionType): string | undefined => (action.constructor as any).actionName),
        filter((actionName: string | undefined): actionName is string => !!actionName),
        switchMap((actionName: string) => this.translateService.get(`Toasts.Actions.${actionName}.Successful`)),
      )
      .subscribe((translation: string | any) => {
        if (!translation) {
          return;
        }

        this.toastsService.success({
          heading: translation,
          autoclose: true,
        });
      });

    this.actions$
      .pipe(
        ofActionErrored(...this.ERRORED_ACTIONS_FOR_TOASTS),
        // DO NOT USE action.constructor.name here: prod build obfuscates so when class name is obfuscated we are not able to get correct translation for it
        map((completion: ActionCompletion<ActionType>): string | undefined => (completion.action.constructor as any).actionName),
        filter((actionName: string | undefined): actionName is string => !!actionName),
        switchMap((actionName: string) => this.translateService.get(`Toasts.Actions.${actionName}.Errored`)),
      )
      .subscribe((translation: string | any) => {
        if (!translation) {
          return;
        }

        this.toastsService.error({
          heading: translation,
          autoclose: false,
        });
      });
  }

  private initBlockingLoader(): void {
    this.actions$
      .pipe(
        ofActionDispatched(...this.ACTIONS_FOR_BLOCKING_LOADER),
      )
      .subscribe(() => {
        this.blockingLoaderService.start();
      });

    this.actions$
      .pipe(
        ofActionCompleted(...this.ACTIONS_FOR_BLOCKING_LOADER),
      )
      .subscribe(() => {
        this.blockingLoaderService.stop();
      });
  }

  private initNonBlockingLoader(): void {
    // TODO Check later
    // Service can implement onDestroy, so ALL services all over the app with subscriptions should correctly destroy them
    // This is not critical for browsers can be so for server side rendering
    this.router
      .events
      .pipe(
        filter((event: Event) => event instanceof RouteConfigLoadStart),
      )
      .subscribe(() => {
        this.nonBlockingLoaderService.start();
      });

    this.router
      .events
      .pipe(
        filter((event: Event) => event instanceof RouteConfigLoadEnd),
      )
      .subscribe(() => {
        this.nonBlockingLoaderService.stop();
      });

    this.actions$
      .pipe(
        ofActionDispatched(...this.ACTIONS_FOR_NON_BLOCKING_LOADER),
      )
      .subscribe(() => {
        this.nonBlockingLoaderService.start();
      });

    this.actions$
      .pipe(
        ofActionCompleted(...this.ACTIONS_FOR_NON_BLOCKING_LOADER),
      )
      .subscribe(() => {
        this.nonBlockingLoaderService.stop();
      });
  }

}
