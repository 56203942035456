import { CompanyConnection } from './company';
import { PaymentProvider } from './external-system';
import { ReportListRequestBase, ReportListResponseBase, SortDirectionType } from './report';

export enum PayoutTransactionReportSortKeyType {
  TRANSACTION_DATE_UTC = 0,
  PAYMENT_PROVIDER = 1,
}

export const PAYOUT_TRANSACTION_REPORTS_SORT_KEY_TYPES: PayoutTransactionReportSortKeyType[] = [
  PayoutTransactionReportSortKeyType.TRANSACTION_DATE_UTC,
  PayoutTransactionReportSortKeyType.PAYMENT_PROVIDER,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPayoutTransactionReportSortKeyType(sortKeyType: any): sortKeyType is PayoutTransactionReportSortKeyType {
  return PAYOUT_TRANSACTION_REPORTS_SORT_KEY_TYPES.includes(sortKeyType);
}

export enum PayoutTransactionReportStatus {
  QUEUED = 0,
  BOOKKEPT = 1,
  BOOKKEPT_TO_INTERIM_ACCOUNT = 2,
  BOOKKEPT_WITH_WARNING = 3,
  FAILED_TO_BOOKKEEP = 4,
  DUPLICATE = 5,
  CORRECTED = 6,
}

export const PAYOUT_TRANSACTION_REPORT_STATUSES: PayoutTransactionReportStatus[] = [
  PayoutTransactionReportStatus.QUEUED,
  PayoutTransactionReportStatus.BOOKKEPT,
  PayoutTransactionReportStatus.BOOKKEPT_TO_INTERIM_ACCOUNT,
  PayoutTransactionReportStatus.BOOKKEPT_WITH_WARNING,
  PayoutTransactionReportStatus.FAILED_TO_BOOKKEEP,
  PayoutTransactionReportStatus.DUPLICATE,
  PayoutTransactionReportStatus.CORRECTED,
];

export enum PayoutTransactionReportType {
  SALE = 'SALE',
  REFUND = 'REFUND',
}

export interface PayoutTransactionReport {
  id: number;
  provider: string;
  sourceId: string | null;
  transactionId: string;
  transactionType: PayoutTransactionReportType | string;
  transactionDateUtc: string;
  transactionAmount: number;
  orderId: string | null;
  feeAmount: number;
  bookkeepingDetails: BookkeepingDetails[];
  statusType: PayoutTransactionReportStatus;
  statusMessage: string | null;
}

export type PayoutTransactionCorrection = Pick<PayoutTransactionReport, 'provider' | 'sourceId' | 'transactionId' | 'transactionType' | 'statusType' | 'statusMessage'> & {
  processingDateTimestamp: number;
};

export function isPayoutTransactionReportFailed(report: PayoutTransactionReport): boolean {
  return report.statusType === PayoutTransactionReportStatus.FAILED_TO_BOOKKEEP;
}

export function isPayoutTransactionReportBookkeptToInterimAccount(report: PayoutTransactionReport): boolean {
  return report.statusType === PayoutTransactionReportStatus.BOOKKEPT_TO_INTERIM_ACCOUNT;
}

export function isPayoutTransactionReportCorrectable(report: PayoutTransactionReport): boolean {
  return isPayoutTransactionReportRetryable(report) || isPayoutTransactionReportMatchableToInvoice(report) || isPayoutTransactionReportIgnorable(report);
}

export function isPayoutTransactionReportRetryable(report: PayoutTransactionReport): boolean {
  return isPayoutTransactionReportFailed(report);
}

export function isPayoutTransactionReportMatchableToInvoice(report: PayoutTransactionReport): boolean {
  return isPayoutTransactionReportBookkeptToInterimAccount(report) && (report.transactionType === PayoutTransactionReportType.SALE || report.transactionType === PayoutTransactionReportType.REFUND);
}

export function isPayoutTransactionReportBookkeepableToAccount(report: PayoutTransactionReport): boolean {
  return isPayoutTransactionReportBookkeptToInterimAccount(report);
}

export function isPayoutTransactionReportIgnorable(report: PayoutTransactionReport): boolean {
  return isPayoutTransactionReportFailed(report) || isPayoutTransactionReportBookkeptToInterimAccount(report);
}

export function trackByPayoutTransactionReportId(index: number, payoutTransactionReport: PayoutTransactionReport): number {
  return payoutTransactionReport.id;
}

export enum BookkeepingDetailsMatchedDocumentType {
  INVOICE = 'Invoice',
  CREDIT_NOTE = 'CreditNote',
}

export type BookkeepingDetails = BookkeepingDetailsNotMatched | BookkeepingDetailsMatchedToInvoice | BookkeepingDetailsMatchedToCreditNote;

export type BookkeepingDetailsNotMatched = {
  journalEntryId: string;
  journalEntryNr: string;
  journalEntryUrl: string | null;
  matchedDocumentId: null;
  matchedDocumentNr: null;
  matchedDocumentType: null;
  matchedInvoiceRefDocumentId: null;
  matchedInvoiceRefDocumentNr: null;
};

export type BookkeepingDetailsMatchedToInvoice = {
  journalEntryId: string;
  journalEntryNr: string;
  journalEntryUrl: string | null;
  matchedDocumentId: string;
  matchedDocumentNr: string;
  matchedDocumentType: BookkeepingDetailsMatchedDocumentType.INVOICE;
  matchedInvoiceRefDocumentId: null;
  matchedInvoiceRefDocumentNr: null;
};

export type BookkeepingDetailsMatchedToCreditNote = {
  journalEntryId: string;
  journalEntryNr: string;
  journalEntryUrl: string | null;
  matchedDocumentId: string;
  matchedDocumentNr: string;
  matchedDocumentType: BookkeepingDetailsMatchedDocumentType.CREDIT_NOTE;
  matchedInvoiceRefDocumentId: string;
  matchedInvoiceRefDocumentNr: string;
};


export enum PayoutSummaryBookkeepingReportSortKeyType {
  PAYOUT_DATE_UTC = 0,
  SOURCE_ID = 1,
  JOURNAL_ENTRY_NUMBER = 2,
  INCOME_AMOUNT = 3,
  FEE_AMOUNT = 4,
  VAT_AMOUNT = 5,
  STATUS = 6,
}

export const PAYOUT_SUMMARY_BOOKKEEPING_REPORT_SORT_KEY_TYPES: PayoutSummaryBookkeepingReportSortKeyType[] = [
  PayoutSummaryBookkeepingReportSortKeyType.PAYOUT_DATE_UTC,
  PayoutSummaryBookkeepingReportSortKeyType.SOURCE_ID,
  PayoutSummaryBookkeepingReportSortKeyType.JOURNAL_ENTRY_NUMBER,
  PayoutSummaryBookkeepingReportSortKeyType.INCOME_AMOUNT,
  PayoutSummaryBookkeepingReportSortKeyType.FEE_AMOUNT,
  PayoutSummaryBookkeepingReportSortKeyType.VAT_AMOUNT,
  PayoutSummaryBookkeepingReportSortKeyType.STATUS,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPayoutSummaryBookkeepingReportSortKeyType(sortKeyType: any): sortKeyType is PayoutSummaryBookkeepingReportSortKeyType {
  return PAYOUT_SUMMARY_BOOKKEEPING_REPORT_SORT_KEY_TYPES.includes(sortKeyType);
}

export enum PayoutSummaryBookkeepingReportStatus {
  QUEUED = 0,
  BOOKKEPT = 1,
  BOOKKEPT_WITH_WARNING = 2,
  FAILED_TO_BOOKKEEP = 3,
}

export interface PayoutSummaryBookkeepingReport {
  payoutReportId: number;
  payoutId: string;
  payoutDateTimeStamp: number;
  provider: string;
  sourceType: string;
  sourceId: string;
  status: PayoutSummaryBookkeepingReportStatus;
  statusMessage: string | null;
  journalEntryId: string | null;
  journalEntryNr: string | null;
  journalEntryDraftGroup: string | null;
  incomeAmountExclVat: number | null;
  vatAmount: number | null;
  paymentVendorAmount: number | null;
  feeAmount: number | null;
  fallbackAmount: number | null;
  journalEntryUrl: string | null;
}

export function trackByPayoutSummaryBookkeepingReportId(index: number, payoutSummaryBookkeepingReport: PayoutSummaryBookkeepingReport): PayoutSummaryBookkeepingReport['payoutReportId'] {
  return payoutSummaryBookkeepingReport.payoutReportId;
}

export interface PayoutTransactionReportListRequest extends ReportListRequestBase {
  sortDirection: SortDirectionType | null;
  sortKey: PayoutTransactionReportSortKeyType | null;
  searchKey: string | null;
  paymentProvider: PaymentProvider[] | null;
  status: PayoutTransactionReportStatus[] | null;
  connectionId: CompanyConnection['externalSystemId'][] | null;
}

export interface PayoutTransactionReportListResponse extends ReportListResponseBase {
  transactionReports: PayoutTransactionReport[];
}

export interface PayoutSummaryBookkeepingReportListRequest extends ReportListRequestBase {
  sortDirection: SortDirectionType | null;
  sortKey: PayoutSummaryBookkeepingReportSortKeyType | null;
  searchKey: string | null;
  paymentProvider: PaymentProvider[] | null;
  status: PayoutSummaryBookkeepingReportStatus[] | null;
  connectionId: CompanyConnection['externalSystemId'][] | null;
}

export interface PayoutSummaryBookkeepingReportListResponse extends ReportListResponseBase {
  reports: PayoutSummaryBookkeepingReport[];
}
